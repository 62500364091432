import { useRef } from 'react'

import styled from 'styled-components'
import useTranslation from 'next-translate/useTranslation'
import useReveal from 'hooks/useReveal'
import Reveal from 'components/Reveal'

import device from 'utils/device'

export default function Curve() {
    const pathRef = useRef();
    const { t } = useTranslation('home')

    const isVisible = useReveal(pathRef, { threshold: 0.5 });


    return (
        <Container ref={pathRef}>
            <Texts>
                <Reveal>
                    <h2 className="h2">{t('home_curve_name')}</h2>
                </Reveal>
                <Reveal delay="2">
                    <p>{t('home_curve_description')}</p>
                </Reveal>
            </Texts>
            <Reveal delay="3">
                <Illustration viewBox="0 0 826 412">
                    <g data-name="Group 1216" transform="translate(-299 -10485)">
                        <text
                            data-name="without medication"
                            transform="translate(507 10894)"
                            fontSize="16"
                            fontFamily="Archivo-SemiBold, Archivo"
                            fontWeight="600"
                        >
                            <tspan x="-69.76" y="0">
                                without medication
                            </tspan>
                        </text>
                        <text
                            data-name="with medication"
                            transform="translate(678 10894)"
                            fontSize="16"
                            fontFamily="Archivo-SemiBold, Archivo"
                            fontWeight="600"
                        >
                            <tspan x="-57.8" y="0">
                                with medication
                            </tspan>
                        </text>
                        <text
                            data-name="with Vital Habits longevity practices"
                            transform="translate(909 10894)"
                            fontSize="16"
                            fontFamily="Archivo-SemiBold, Archivo"
                            fontWeight="600"
                        >
                            <tspan x="-129.696" y="0">
                                with Vital Habits longevity practices
                            </tspan>
                        </text>
                        <circle cx="6" cy="6" r="6" transform="translate(417 10882)" fill="#497a51" />
                        <circle data-name="bullet" cx="6" cy="6" r="6" transform="translate(601 10882)" fill="#38869e" />
                        <circle data-name="bullet" cx="6" cy="6" r="6" transform="translate(760 10882)" fill="#fb7e59" />
                    </g>
                    <path d="M51 321h774" fill="#d6d6d6" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                    <path
                        data-name="line"
                        d="M32.999 1v302"
                        fill="#d6d6d6"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                    <path d="M51 321a18 18 0 0 1-18-18" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="2" />
                    <path
                        d="m819 315 6 6-6 6"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                    <path
                        data-name="arrow"
                        d="m27 7 6-6 6 6"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                    <text data-name="Life span" transform="translate(429 353)" fontSize="16" fontFamily="Archivo-Regular, Archivo">
                        <tspan x="-31.824" y="0">
                            Life span
                        </tspan>
                    </text>
                    <text
                        data-name="Health span"
                        transform="rotate(-90 87.5 73.5)"
                        fontSize="16"
                        fontFamily="Archivo-Regular, Archivo"
                    >
                        <tspan x="-42.464" y="0">
                            Health span
                        </tspan>
                    </text>
                    <Path1
                        d="M49 59.034S376.6 14.262 495.915 305"
                        fill="none"
                        stroke="#497a51"
                        strokeLinecap="round"
                        strokeWidth="4"
                        animate={isVisible}
                    />
                    <Path2
                        d="M49 59.011s291.669-40.617 420.905 163.243c59.7 96.641 92.572 22.1 117.426 82.745"
                        fill="none"
                        stroke="#38869e"
                        strokeLinecap="round"
                        strokeWidth="4"
                        animate={isVisible}
                    />
                    <Path3
                        data-name="Path 63831"
                        d="M49 59s49.356-5.9 149.633-4.217c67.669.416 147.978 4.212 217.372 16.537 155.942 27.7 176.667 90.911 176.667 90.911S621.85 207.222 622 305"
                        fill="none"
                        stroke="#fb7e59"
                        strokeLinecap="round"
                        strokeWidth="4"
                        animate={isVisible}
                    />
                </Illustration>
                <IllustrationMobile xmlns="http://www.w3.org/2000/svg" viewBox="0 0 344.5 177.5">
                    <g transform="translate(22.32 169.203)">
                        <text
                            data-name="without medication"
                            transform="translate(31.68 6.297)"
                            fontSize="9"
                            fontFamily="Archivo-SemiBold, Archivo"
                            fontWeight="600"
                        >
                            <tspan x="-39.24" y="0">
                                without medication
                            </tspan>
                        </text>
                        <text
                            data-name="with medication"
                            transform="translate(120.68 6.297)"
                            fontSize="9"
                            fontFamily="Archivo-SemiBold, Archivo"
                            fontWeight="600"
                        >
                            <tspan x="-32.513" y="0">
                                with medication
                            </tspan>
                        </text>
                        <text
                            data-name="with Vital Habits longevity practices"
                            transform="translate(243.68 6.297)"
                            fontSize="9"
                            fontFamily="Archivo-SemiBold, Archivo"
                            fontWeight="600"
                        >
                            <tspan x="-72.954" y="0">
                                with Vital Habits longevity practices
                            </tspan>
                        </text>
                        <circle cx="2.5" cy="2.5" r="2.5" transform="translate(-16.32 .297)" fill="#497a51" />
                        <circle data-name="bullet" cx="2.5" cy="2.5" r="2.5" transform="translate(78.68 .297)" fill="#38869e" />
                        <circle data-name="bullet" cx="2.5" cy="2.5" r="2.5" transform="translate(161.68 .297)" fill="#fb7e59" />
                    </g>
                    <path d="M25.145 132.326h318.854" fill="#d6d6d6" stroke="#000" strokeLinecap="round" strokeLinejoin="round" />
                    <path
                        data-name="line"
                        d="M17.73.5v124.411"
                        fill="#d6d6d6"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path d="M25.145 132.326a7.415 7.415 0 0 1-7.415-7.415" fill="none" stroke="#000" strokeLinecap="round" />
                    <path
                        d="m341.528 129.854 2.472 2.472-2.472 2.472"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        data-name="arrow"
                        d="M15.258 2.969 17.73.497l2.472 2.472"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <text data-name="Life span" transform="translate(173 147.5)" fontSize="10" fontFamily="Archivo-Regular, Archivo">
                        <tspan x="-19.89" y="0">
                            Life span
                        </tspan>
                    </text>
                    <text
                        data-name="Health span"
                        transform="rotate(-90 39.75 30.75)"
                        fontSize="10"
                        fontFamily="Archivo-Regular, Archivo"
                    >
                        <tspan x="-26.54" y="0">
                            Health span
                        </tspan>
                    </text>
                    <Path4
                        d="M27.619 22.365S164.198 3.7 213.942 124.911"
                        fill="none"
                        stroke="#497a51"
                        strokeLinecap="round"
                        strokeWidth="2"
                        animate={isVisible}
                    />
                    <Path5
                        d="M27.619 22.354s121.6-16.933 175.48 68.059c24.887 40.29 38.594 9.213 48.956 34.5"
                        fill="none"
                        stroke="#38869e"
                        strokeLinecap="round"
                        strokeWidth="2"
                        animate={isVisible}
                    />
                    <Path6
                        data-name="Path 63507"
                        d="M27.619 22.35s20.577-2.462 62.383-1.758c28.212.173 61.694 1.756 90.625 6.895 65.014 11.546 73.654 37.9 73.654 37.9s12.165 18.757 12.227 59.521"
                        fill="none"
                        stroke="#fb7e59"
                        strokeLinecap="round"
                        strokeWidth="2"
                        animate={isVisible}
                    />
                </IllustrationMobile>
            </Reveal>
        </Container>
    )
}

const Container = styled.div`
    padding: 1.6rem 0;
    ${device.mobile} {
        padding: 0.8rem 0;
    }
`

const Texts = styled.div`
    width: 5.88rem;
    margin: 0 auto 0.8rem auto;
    text-align: center;
    h2 {
        margin-bottom: 0.16rem;
    }
    p {
        margin: 0;
    }
    ${device.mobile} {
        margin: 0 auto 0.36rem auto;
        width: var(--wrap-mobile);
        h2 {
            margin-bottom: 0.08rem;
        }
    }
`

const Illustration = styled.svg`
    width: 8.26rem;
    height: 4.12rem;
    margin: 0 auto;
    display: block;
    ${device.mobile} {
        display: none;
    }
`

const IllustrationMobile = styled.svg`
    display: none;
    ${device.mobile} {
        margin: 0 auto;
        display: block;
        width: 3.44rem;
        height: 1.77rem;
    }
`

const Path1 = styled.path`
stroke-dasharray: 600, 600;
stroke-dashoffset: 600;
@keyframes draw1 {
    0% {
      stroke-dasharray: 600, 600;
      stroke-dashoffset: 600;
    }
    100% {
      stroke-dasharray: 600, 600;
      stroke-dashoffset: 0;
    }
  }
${props=>props.animate ? 'animation: draw1 3s 900ms ease-out forwards;' : ''}
`

const Path2 = styled.path`
stroke-dasharray: 700, 700;
stroke-dashoffset: 700;
@keyframes draw2 {
    0% {
      stroke-dasharray: 700, 700;
      stroke-dashoffset: 700;
    }
    100% {
      stroke-dasharray: 700, 700;
      stroke-dashoffset: 0;
    }
  }
${props=>props.animate ? 'animation: draw2 3s 900ms ease-out forwards;' : ''}
`

const Path3 = styled.path`
stroke-dasharray: 800, 800;
stroke-dashoffset: 800;
@keyframes draw3 {
    0% {
      stroke-dasharray: 800, 800;
      stroke-dashoffset: 800;
    }
    100% {
      stroke-dasharray: 800, 800;
      stroke-dashoffset: 0;
    }
  }
${props=>props.animate ? 'animation: draw3 3s 900ms ease-out forwards;' : ''}
`


const Path4 = styled.path`
stroke-dasharray: 400, 400;
stroke-dashoffset: 400;
@keyframes draw4 {
    0% {
      stroke-dasharray: 400, 400;
      stroke-dashoffset: 400;
    }
    100% {
      stroke-dasharray: 400, 400;
      stroke-dashoffset: 0;
    }
  }
${props=>props.animate ? 'animation: draw4 3s ease-out forwards;' : ''}
`

const Path5 = styled.path`
stroke-dasharray: 500, 500;
stroke-dashoffset: 500;
@keyframes draw5 {
    0% {
      stroke-dasharray: 500, 500;
      stroke-dashoffset: 500;
    }
    100% {
      stroke-dasharray: 500, 500;
      stroke-dashoffset: 0;
    }
  }
${props=>props.animate ? 'animation: draw5 3s 900ms ease-out forwards;' : ''}
`

const Path6 = styled.path`
stroke-dasharray: 600, 600;
stroke-dashoffset: 600;
@keyframes draw6 {
    100% {
      stroke-dasharray: 600, 600;
      stroke-dashoffset: 0;
    }
  }
${props=>props.animate ? 'animation: draw6 3s 900ms ease-out forwards;' : ''}
`